import tw from "twin.macro"
import { StyledButton } from "../../styled/Button"
import { StyledColumn } from "../../styled/Column"
import { StyledRow } from "../../styled/Row"
import { Icon } from "../Icon/Icon"

export const Row = tw(StyledRow)`-m-6 md:-m-4 w-auto justify-start xs:mt-8`
export const Column = tw(StyledColumn)`p-6 md:p-4 xs:w-full flex justify-center xs:p-2`
export const Button = tw(StyledButton)`lg:px-12 text-center inline-block mr-4 flex items-center xs:p-2`
export const SocialIcon = tw(Icon)`mr-2`
