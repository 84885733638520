import React, { useMemo, useEffect, useState } from "react"

import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"
import { useTemplate } from "../../hooks/useTemplate"
import { useFunctions } from "../../hooks/useFunctions"
import { useSanityEvent } from "../../hooks/useSanity"
import { useCalendar } from "../../hooks/useCalendar"

export const withEventRegister = Component =>
  React.memo(({ name = "EventRegister", event, live, loading, ...props }) => {
    const { ready, customer, member, setActivePopup, setRedirectUrl } = useApp()
    const {
      helpers: { sanityContent },
    } = useCore()
    const { eventRegister, loading: loadingFunctions } = useFunctions()
    const { event: template } = useTemplate()
    const { event: eventRefresh, getEvent } = useSanityEvent()
    const [loaded, setLoaded] = useState(false)
    const [activePopup, setPopup] = useState(false)

    useEffect(() => {
      if (live?.length > 0 && !loading) setLoaded(true)
    }, [live, loading])

    const attending =
      live?.length || eventRefresh
        ? live?.[0]?.attendees?.filter(item => item?._id == member?._id)?.length ||
          eventRefresh?.attendees?.filter(item => item?._id == member?._id)?.length
        : event?.attendees?.filter(item => item?.id == member?._id)?.length

    const calendarEvent = {
      title: event.title,
      description: event.summary,
      start: event.date,
      duration: [1, "hour"],
    }

    const handleSubmit = async () => {
      const { generateAllLinks } = useCalendar(calendarEvent)
      const calendarLinks = generateAllLinks()

      const success = await eventRegister({ id: event?.id, member, event: { ...event, calendarLinks } })
      if (success) {
        getEvent({ id: event?.id })
      }
    }

    if (ready && event?.viponly && !member?.membership.vip && !event?.freeevent) {
      setRedirectUrl(`/events/${event?.slug?.current}/register`)
      setActivePopup(true)
    }

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          event={event}
          template={template}
          customer={customer}
          member={member}
          attending={attending}
          handleSubmit={handleSubmit}
          loaded={loaded}
          loading={loadingFunctions}
          handlePopup={setPopup}
          activePopup={activePopup}
          calendarEvent={calendarEvent}
        />
      ),
      [loadingFunctions, attending, live, loaded, activePopup]
    )
  })
