import React from "react"
import { Link } from "gatsby"

import { Checkbox } from "../Checkbox/Checkbox"
import { Textarea, Frame, Title, Description, Field, Button, ButtonWrapper } from "./Form.styled"

export const Form = ({
  title,
  description,
  fields,
  buttonClick,
  buttonLink,
  buttonText,
  condensed,
  center,
  dark,
  loading = false,
}: Props): JSX.Element => (
  <Frame condensed={condensed}>
    <Title description={description}>{title}</Title>
    {description ? <Description>{description}</Description> : null}
    {fields
      .filter(({ name }) => name)
      .map(({ link, name, placeholder = name, type = `text`, value = "" }) =>
        type === `checkbox` ? (
          <Checkbox key={name?.toString()} name={name} link={link} text={placeholder} />
        ) : (
          <Field
            key={name?.toString()}
            as={type === `textarea` ? Textarea : null}
            condensed={condensed}
            dark={dark}
            textarea={type === `textarea`}
            placeholder={placeholder}
            name={name}
            type={type}
            value={value}
          />
        )
      )}
    <ButtonWrapper center={center} condensed={condensed}>
      <Button
        as={buttonLink ? Link : null}
        center={center}
        size={`large`}
        colour={`dark`}
        theme={`primary`}
        onClick={buttonClick ? buttonClick : null}
        to={buttonLink}
        loading={loading}
      >
        {buttonText}
      </Button>
    </ButtonWrapper>
  </Frame>
)

export interface Props {
  title?: string
  description?: string
  center?: boolean
  condensed?: boolean
  dark?: boolean
  buttonClick?: () => void
  buttonLink?: string
  buttonText?: string
  loading?: boolean
  fields?: Array<{
    name: string
    value?: string
    placeholder: string
    type?: string
    link?: any
  }>
}
