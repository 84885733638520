import { google, outlook, office365, yahoo, ics } from "calendar-link"
const INITIAL_EVENT = {
  title: "",
  description: "",
  start: "",
  duration: [],
}

export const useCalendar = (defaultEvent = {}) => {
  const event = { ...INITIAL_EVENT, ...defaultEvent }

  const generateLink = type => {
    const eventMap = {
      google: google,
      outlook: outlook,
      office: office365,
      yahoo: yahoo,
      ics: ics,
    }

    const calendarLink = eventMap[type](event)

    return calendarLink
  }

  const generateAllLinks = () => {
    return {
      google: google(event),
      outlook: outlook(event),
      office: office365(event),
      yahoo: yahoo(event),
      ics: ics(event),
    }
  }

  return {
    generateLink,
    generateAllLinks,
  }
}
