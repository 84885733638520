import { StyledPopupContent, StyledPopupTitle } from "../../styled/Popup"
import { Icon } from "../Icon/Icon"
import { Popup } from "../Popup/Popup"
import { Close } from "../Popup/Popup.styled"
import { Button, Column, Row, SocialIcon } from "./AddEventToCalendar.styled"
import { withAddEventToCalendar } from "./withAddEventToCalendar"

export const AddEventToCalendar = withAddEventToCalendar(
  ({ activePopup, handlePopup, handleSubmit }: Props): JSX.Element => {
    const services = [
      { icon: "google", label: "Google", value: "google" },
      { icon: "microsoft", label: "Office", value: "office" },
      { icon: "outlook", label: "Outlook", value: "outlook" },
      { icon: "yahoo", label: "Yahoo", value: "yahoo" },
    ]
    return (
      <Popup active={activePopup} setActive={handlePopup} full>
        <Close type={`button`} onClick={() => handlePopup(false)}>
          <Icon name={"cross"} size={"s"} colour={"tertiary"} />
        </Close>
        <StyledPopupTitle>Add event to calendar</StyledPopupTitle>
        <StyledPopupContent>
          <Row wrap center>
            {services.map((service, index) => {
              return (
                <Column key={index}>
                  <Button icon theme={"primary"} size={"large"} colour={"outline"} onClick={() => handleSubmit(service.value)}>
                    <SocialIcon size={"l"} name={service.icon} />
                  </Button>
                </Column>
              )
            })}
          </Row>
        </StyledPopupContent>
      </Popup>
    )
  }
)

export interface Props {
  activePopup?: boolean
  handlePopup?: (show: boolean) => void
  handleSubmit?: (type: string) => void
  event?: any
}
