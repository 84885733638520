import React from "react"

import { withCheckbox } from "./withCheckbox"
import { Label, Input, Content, StyledLink, Marker } from "./Checkbox.styled"

export const Checkbox = withCheckbox(({ checked = false, text, link, active, handleActive, alignLeft, theme, bold, ...props }: Props): JSX.Element => {
  return (
    <Label onClick={() => handleActive(!active)} alignLeft={alignLeft}>
      <Input {...props} type={`checkbox`} checked={checked || active} />
      <Marker active={active} theme={theme} />
      <Content bold={bold}>
        {text}
        {link ? <StyledLink to={link.url}>{link.text}</StyledLink> : null}
      </Content>
    </Label>
  )
})

export interface Props {
  text: string
  checked?: boolean
  link?: any
  name?: string
  active?: boolean
  handleActive?: (index: boolean) => void
  alignLeft?: boolean
  theme?: "primary" | "secondary"
  bold?: boolean
}
