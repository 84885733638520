import React, { useMemo } from "react"
import { useApp } from "../../hooks/useApp"
import { useCalendar } from "../../hooks/useCalendar"
import { useSanityEvent } from "../../hooks/useSanity"

export const withAddEventToCalendar = Component =>
  React.memo(({ name = "AddEventToCalendar", activePopup, handlePopup, event }) => {
    const { setFixed } = useApp()
    const { generateLink } = useCalendar(event)

    if (!activePopup) setFixed(activePopup)

    const handleSubmit = type => {
      const calendarLink = generateLink(type)
      window.open(calendarLink, "_blank")
    }

    Component.displayName = name
    return useMemo(() => <Component activePopup={activePopup} handlePopup={handlePopup} handleSubmit={handleSubmit} />, [activePopup])
  })
