import React from "react"

import { withEventRegister } from "./withEventRegister"
import { getFluidGatsbyImage, routeResolver } from "../Wrappers/Helpers"

import { Form } from "../Form/Form"
import { Container, Button, Title, Emoji, Members, Member, Attendees, Image, Column, Link, Row, Content } from "./EventRegister.styled"
import { AddEventToCalendar } from "./AddEventToCalendar"

export const EventRegister = withEventRegister(
  ({
    event: { attendees, slug, title, type, id },
    member,
    customer,
    attending,
    handleSubmit = () => null,
    loading,
    loaded,
    template: {
      back,
      registerButton,
      registerFirstName,
      registerLastName,
      registerEmail,
      registerSubtitle,
      registerTitle,
      registeredTitle,
      registeredContent,
      registeredIcon,
      registeredMemberCount,
      registeredMembers,
    },
    activePopup,
    handlePopup,
    calendarEvent,
  }: Props): JSX.Element =>
    loaded && (
      <Container width={`md`}>
        {attending ? (
          <>
            <Title>{registeredTitle}</Title>
            <Content>{registeredContent}</Content>
            <Emoji>{registeredIcon}</Emoji>
            <Row center>
              <Column>
                <Button
                  size={`medium`}
                  theme={`primary`}
                  colour={`dark`}
                  onClick={() => {
                    handlePopup(true)
                  }}
                >
                  Add to Calendar
                </Button>
              </Column>
              <Column>
                <Button as={Link} to={routeResolver({ type: `event` })} size={`medium`} theme={`primary`} colour={`outline`} title={back}>
                  {back}
                </Button>
              </Column>
            </Row>
            <Row center>
              <Column width={`w-1/2`}>
                {attendees?.length > 0 && (
                  <Members>
                    {attendees?.slice(0, Math.min(attendees?.length, 4)).map(
                      (item, i) =>
                        item?.id && (
                          <Member index={i} key={item?.id}>
                            {item?.image && (
                              <Image alt={item?.image.alt || title} size={`rounded`} image={getFluidGatsbyImage(item?.image, { maxWidth: 100 })} />
                            )}
                          </Member>
                        )
                    )}
                  </Members>
                )}
              </Column>
              <Column width={`w-1/2`}>
                <Attendees>{registeredMemberCount?.replace(`{count}`, attendees?.length || 0)}</Attendees>
                {/* <Link
                theme={`secondary`}
                colour={`outline`}
                title={registeredMembers}
                to={`${routeResolver({ item: { slug, type } })}${routeResolver({ type: `event_members` })}`}
              >
                {registeredMembers}
              </Link> */}
              </Column>
            </Row>
            <AddEventToCalendar activePopup={activePopup} handlePopup={handlePopup} event={calendarEvent} />
          </>
        ) : (
          <Form
            title={registerTitle.replace(`{title}`, title)}
            description={registerSubtitle}
            buttonText={loading ? "Saving..." : registerButton}
            buttonClick={() => handleSubmit()}
            loading={loading}
            center
            fields={[
              {
                name: `first_name`,
                placeholder: registerFirstName,
                type: `text`,
                value: customer?.firstName || member?.title?.split(" ")?.[0] || "",
              },
              {
                name: `last_name`,
                placeholder: registerLastName,
                type: `text`,
                value: customer?.lastName || member?.title?.split(" ")?.[1] || "",
              },
              {
                name: `email`,
                placeholder: registerEmail,
                type: `email`,
                value: customer?.email || "",
              },
            ]}
          />
        )}
      </Container>
    )
)

export interface Props {
  event: {
    attendees: Array<any>
    slug: {
      current: string
    }
    title: string
    type: string
    id: string
  }
  customer?: any
  member?: any
  attending?: boolean
  handleSubmit?: () => void
  loading?: boolean
  loaded?: boolean
  template?: any
  activePopup?: boolean
  handlePopup?: (show: boolean) => void
  calendarEvent?: any
}
