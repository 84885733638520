import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { withMembership } from "../../hoc/withMember"
import { EventRegister as Template } from "../../components/Event/EventRegister"
import { useSanityEvent } from "../../hooks/useSanity"
import { useApp } from "../../hooks/useApp"

export const query = graphql`
  query($id: String!) {
    event: sanityEvent(_id: { eq: $id }) {
      ...GatsbyEventFragment
    }
    # template: sanityTemplateEvent {
    #   additionalRelatedText
    #   additionalShareText
    #   settingShowRelated
    #   settingShowSocialShare
    # }
    # TODO: LIVE QUERY FOR RELATED EVENTS
  }
`

export default withAuthentication(
  ({ data, ...props }): JSX.Element => {
    const { ready } = useApp()
    const { events, loadingLiveEvents, getEvents } = useSanityEvent()
    if (ready && !events?.length) getEvents({ ids: [data?.event?.id] })

    if (data?.event?.freeevent) {
      return <Template {...props} {...data} live={events} loading={loadingLiveEvents} />
    }

    return withMembership(
      ({ data, events, loadingLiveEvents, ...props }): JSX.Element => <Template {...props} {...data} live={events} loading={loadingLiveEvents} />
    )({ data, events, loadingLiveEvents, ...props })
  }
)
