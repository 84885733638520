import { GatsbyImage } from "gatsby-plugin-image"
import tw, { styled } from "twin.macro"

import { StyledButton } from "../../styled/Button"
import { StyledContainer } from "../../styled/Container"
import { H2, P } from "../../styled/Text"
import { StyledRow } from "../../styled/Row"

export { StyledColumn as Column } from "../../styled/Column"
export { StyledLink as Link } from "../../styled/Link"
export { P } from "../../styled/Text"

export const Container = tw(StyledContainer)`h-full bg-grey-lightest md:bg-transparent md:h-auto flex flex-col items-center`
export const Button = tw(StyledButton)`block md:w-auto mt-8 mb-8 ml-2 text-center`
export const Title = tw(H2)`text-grey-dark mt-8 w-full md:mt-0 mb-8 text-center`
export const Emoji = tw.p`text-12xl text-center w-full leading-none`
export const Members = tw.div`relative flex justify-end mr-18`
export const Member = styled.div`
  ${tw`absolute right-0 z-4`}
  ${({ index }) =>
    !index
      ? tw`relative`
      : index === 1
      ? tw`transform translate-x-35-pc z-3`
      : index === 2
      ? tw`transform translate-x-70-pc z-2`
      : index === 3
      ? tw`transform translate-x-105-pc z-1`
      : null}
`
export const Content = tw(P)`text-center mb-10 px-24`
export const Attendees = tw(P)`uppercase text-grey-mid font-bold text-xxs`
export const Image = tw(GatsbyImage)`inline-block w-full absolute left-0 rounded-full w-12 h-12 border-4 border-white z-4`
export const Row = tw(StyledRow)`justify-center`
